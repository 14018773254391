/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box, IconButton, Grid, Typography, Tooltip } from '@mui/material';
import { styled, ThemeProvider, useTheme } from '@mui/material/styles';
import {
	collection,
	doc,
	getDoc,
	onSnapshot,
	query,
	updateDoc,
	where,
	orderBy,
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import ReplyIcon from '@mui/icons-material/Reply';
import InventoryIcon from '@mui/icons-material/Inventory';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import LuggageIcon from '@mui/icons-material/Luggage';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DealMessagesDetailsModal from '../../components/ReusableObjectDetailsModal';
import ContractSignedView from '../../components/ContractSignedView';
import { useNavigate } from 'react-router-dom';
import useAuthContext from '../../hooks/useAuthContext';
import ActionModal from './components/ActionModal';
import ReplyMessage from './components/ReplyMessage';
import ViewMessage from './components/ViewMessage';
import { decompressDayParts } from '../AQHCalculator/utils/utils';
import NotFoundModal from './components/NotFound';
import { isAdminVerify } from '../../utils';
import { getTheme } from './utils';

const MessagesTab = () => {
	const [data, setData] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [openContractSignedView, setOpenContractSignedView] = useState(false);
	const [esignDocumentId, setEsignDocumentId] = useState('');
	const [service, setService] = useState(null);
	const [modalDetails, setModalDetails] = useState({});
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const [userRole, setUserRole] = useState('rep');
	const [actionModalOpen, setActionModalOpen] = useState(false);
	const [replyMessageModal, setReplyMessageModal] = useState(false);
	const [viewMessageModal, setViewMessageModal] = useState(false);
	const [currentMessage, setCurrentMessage] = useState(null);
	const [docActionId, setDocActionId] = useState(null);
	const [action, setAction] = useState(null);
	const [notFoundModalOpen, setNotFoundModalOpen] = useState(false);
	const ashleyUser = 'gJvU6wItHuS38lFXuoFuLdNCjEL2';
	const [page, setPage] = useState(0);

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const theme = useTheme();

	const getFontWeight = (tableMeta) => {
		if (isAdminVerify(userRole)) {
			return tableMeta?.rowData?.[4]?.state === 'unread' ? 'bold' : 'normal';
		} else {
			return tableMeta?.rowData?.[4]?.repState === 'unread' ? 'bold' : 'normal';
		}
	};

	const getBackgroundColor = (state) => {
		if (
			state === 'approved' ||
			state === 'managerSigned' ||
			state === 'sentClient' ||
			state === 'signedClient'
		) {
			return 'rgb(177, 211, 179)';
		} else if (state === 'denied') {
			return 'rgb(232, 160, 159)';
		}
	};

	const columnsTraveler = [
		{
			name: 'messageDate',
			label: 'Message Date',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							minWidth: '150px',
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
				customBodyRender: (value) => {
					const newDate = new Date(value);
					return newDate.toLocaleDateString('en-US');
				},
			},
		},
		{
			name: 'subject',
			label: 'Subject',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							minWidth: '200px',
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
			},
		},
		{
			name: 'docs',
			label: 'Docs',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							minWidth: '150px',
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						width="200px"
						py={0.8}
					>
						<Grid container spacing={1} columns={{ xs: 8, sm: 18, md: 18 }}>
							<Grid item xs={2} sm={4} md={4}>
								<FlightLandIcon />
							</Grid>

							<Grid item xs={2} sm={4} md={4}>
								<LuggageIcon />
							</Grid>
						</Grid>
					</Box>
				),
			},
		},
		{
			name: 'fromName',
			label: 'From',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							minWidth: '150px',
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
			},
		},
		{
			name: 'to',
			label: 'To',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							minWidth: '150px',
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
			},
		},
		{
			name: 'message',
			label: 'Message',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography
						sx={{
							maxWidth: '500px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							verticalAlign: 'middle',
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'actions',
			label: 'Actions',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							minWidth: '15%',
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						width="200px"
						py={0.8}
					>
						<Grid container spacing={1} columns={{ xs: 8, sm: 18, md: 18 }}>
							<Grid item xs={2} sm={4} md={4}>
								<Tooltip title="Reply" placement="top" arrow>
									<IconButton
										onClick={(_) => {
											replyMessage(value);
										}}
									>
										<ReplyIcon color="success" />
									</IconButton>
								</Tooltip>
							</Grid>

							<Grid item xs={2} sm={4} md={4}>
								<Tooltip title="Look" placement="top" arrow>
									<IconButton
										onClick={(_) => {
											viewMessage(value);
										}}
									>
										<VisibilityIcon color="info" />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Box>
				),
			},
		},
	];

	const columns = [
		{
			name: 'repName',
			label: 'Rep Name',
			options: {
				filter: true,
				sort: true,
				setCellProps: (value) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(value.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography
						key={tableMeta?.rowData?.[4]?.state}
						sx={{ fontWeight: getFontWeight(tableMeta) }}
						onClick={() => {
							const documentData = tableMeta?.rowData?.[4] || null;
							if (tableMeta?.rowData?.[4]?.state !== 'managerSigned') {
								lookAction(documentData);
							}
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'dealName',
			label: 'Deal Name',
			options: {
				filter: true,
				sort: true,
				setCellProps: (value) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(value.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography
						key={tableMeta?.rowData?.[4]?.state}
						sx={{ fontWeight: getFontWeight(tableMeta) }}
						onClick={() => {
							const documentData = tableMeta?.rowData?.[4] || null;
							if (tableMeta?.rowData?.[4]?.state !== 'managerSigned') {
								lookAction(documentData);
							}
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'typeMessage',
			label: 'Message Content',
			options: {
				filter: true,
				sort: true,
				setCellProps: (value) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(value.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography
						key={tableMeta?.rowData?.[4]?.state}
						sx={{ fontWeight: getFontWeight(tableMeta) }}
						onClick={() => {
							const documentData = tableMeta?.rowData?.[4];
							if (tableMeta?.rowData?.[4]?.state !== 'managerSigned') {
								lookAction(documentData);
							}
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'submittedDate',
			label: 'Submitted Date',
			options: {
				filter: true,
				sort: true,
				setCellProps: (value) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(value.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography
						key={tableMeta?.rowData?.[4]?.state}
						sx={{ fontWeight: getFontWeight(tableMeta) }}
						onClick={() => {
							const documentData = tableMeta?.rowData?.[4] || null;
							if (tableMeta?.rowData?.[4]?.state !== 'managerSigned') {
								lookAction(documentData);
							}
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'actions',
			label: 'Actions',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							minWidth: '150px',
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Box
						key={tableMeta?.rowData?.[4]?.state}
						display="flex"
						alignItems="center"
						justifyContent="center"
						width="200px"
						py={0.8}
					>
						<Grid container spacing={1} columns={{ xs: 8, sm: 18, md: 18 }}>
							{tableMeta?.rowData?.[4]?.state !== 'managerSigned' &&
							tableMeta?.rowData?.[4]?.state !== 'signedClient' ? (
								<>
									{/* hide reply button if is a sent client message */}
									{tableMeta?.rowData?.[4]?.state !== 'sentClient' ? (
										<Grid item xs={2} sm={4} md={4}>
											<Tooltip title="Go" placement="top" arrow>
												<IconButton
													onClick={(_) => {
														replyAction(value);
													}}
												>
													<ReplyIcon color="success" />
												</IconButton>
											</Tooltip>
										</Grid>
									) : tableMeta?.rowData?.[4]?.state === 'sentClient' ? (
										<Grid item xs={2} sm={4} md={4}>
											<Tooltip title="Edit" placement="top" arrow>
												<IconButton
													onClick={(_) => {
														editAction(value);
													}}
												>
													<EditIcon color="success" />
												</IconButton>
											</Tooltip>
										</Grid>
									) : (
										''
									)}
									<Grid item xs={2} sm={4} md={4}>
										<Tooltip title="Look" placement="top" arrow>
											<IconButton
												onClick={(_) => {
													lookAction(value);
												}}
											>
												<VisibilityIcon color="info" />
											</IconButton>
										</Tooltip>
									</Grid>
								</>
							) : (
								tableMeta?.rowData?.[4]?.state !== 'signedClient' && (
									// if signed
									<Grid item xs={2} sm={4} md={4}>
										<Tooltip title="View Document" placement="top" arrow>
											<IconButton
												onClick={(_) => {
													lookContractSigned(value);
												}}
											>
												<FindInPageIcon color="info" />
											</IconButton>
										</Tooltip>
									</Grid>
								)
							)}
							{tableMeta?.rowData?.[4]?.state !== 'archived' ? (
								<Grid item xs={2} sm={4} md={4}>
									<Tooltip title="Archive" placement="top" arrow>
										<IconButton
											onClick={(_) => {
												handleArchive(value);
											}}
										>
											<InventoryIcon color="warning" />
										</IconButton>
									</Tooltip>
								</Grid>
							) : (
								''
							)}
							<Grid item xs={2} sm={4} md={4}>
								<Tooltip title="Delete" placement="top" arrow>
									<IconButton
										onClick={(_) => {
											handleDelete(value);
										}}
									>
										<DeleteIcon color="error" />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Box>
				),
			},
		},
	];

	const replyMessage = (value) => {
		setReplyMessageModal(true);
		setCurrentMessage(value);
	};
	const viewMessage = (value) => {
		setViewMessageModal(true);
		setCurrentMessage(value);
	};

	const options = {
		filterType: 'dropdown',
		print: 'false',
		pagination: true,
	};

	const MainBox = styled(Box)(({ theme }) => ({
		margin: '20px auto',
		'.MuiPaper-root': {
			background: 'transparent',
			boxShadow: 'none',
			border: 0,
		},
	}));
	// handle edit action for rep
	const editAction = async (document) => {
		if (document.proposalId) {
			const docRef = doc(db, 'savedProposals', document.proposalId);
			const proposalDocument = await getDoc(docRef);
			if (
				proposalDocument.exists() &&
				proposalDocument.data()?.aqhSavedOptions[0]?.selectedRowsAqh
			) {
				// decompress dayparts
				const decompressedDayParts = await decompressDayParts(
					proposalDocument.data()?.aqhSavedOptions
				);
				const data = {
					...proposalDocument.data(),
					aqhSavedOptions: decompressedDayParts,
					id: document.proposalId,
					editProposal: true,
				};
				navigate('/dashboard/aqhcalculator', { state: { data: data } });
			}
		}
	};
	// handle reply action for manager review and rep review messages
	const replyAction = async (document) => {
		if (document.proposalId) {
			const docRef = doc(db, 'savedProposals', document.proposalId);
			const proposalDocument = await getDoc(docRef);
			if (
				proposalDocument.exists() &&
				proposalDocument.data()?.aqhSavedOptions[0]?.selectedRowsAqh
			) {
				// decompress dayparts
				const decompressedDayParts = await decompressDayParts(
					proposalDocument.data()?.aqhSavedOptions
				);
				// if user is admin, navigate to aqh calculator with selected rows
				if (userRole === 'admin') {
					const array = Object.values(
						proposalDocument.data()?.aqhSavedOptions[0]?.selectedRowsAqh
					);
					navigate('/dashboard/aqhcalculator', {
						state: {
							data: {
								messageId: document.id,
								selectedRowsAqh: array,
								id: document.proposalId,
								messageProposalId: document.proposalId,
								dealTerms: proposalDocument.data()?.dealTerms || null,
								type: 'proposal',
							},
						},
					});
				} else {
					const data = {
						...proposalDocument.data(),
						aqhSavedOptions: decompressedDayParts,
						id: document.proposalId,
						denyReason: document.denyReason,
					};
					navigate('/dashboard/aqhcalculator', { state: { data: data } });
				}
			} else {
				// if proposal not found, show not found modal
				handleNotFoundModal();
			}
		}
	};

	const lookAction = async (docData) => {
		// get document from firebase
		const reference = doc(db, 'reviewMessages', docData.id);
		const document = await getDoc(reference);

		if (document.exists()) {
			// get document data
			const data = document.data();
			// set general data
			const generalData = {
				'Submitted Date': getDateFormatted(data?.submittedDate?.seconds),
				'Deal Name': data?.dealName || null,
			};
			// if is a sent client message
			if (data?.state === 'sentClient') {
				const detailsObj = {
					...generalData,
					'Receiver Name': data?.clientName || null,
					'Receiver Email': data?.clientEmail || null,
					Content: 'The proposal was sent to the client',
				};
				// set modal details
				setModalDetails(detailsObj);
			} else {
				// if is a manager review message
				const detailsObj = {
					...generalData,
					'Rep Name': data?.fromName || null,
					Reviewed:
						data?.state === 'approved' || data?.state === 'denied' ? 'true' : 'false',
					Action: data?.state || null,
					Reviewer: data?.reviewer || 'not yet reviewed',
				};
				// set modal details
				setModalDetails(detailsObj);
			}
			setOpenModal(true);
			// update state to review message
			await updateDoc(reference, { repState: 'read' });
		}
	};

	const lookContractSigned = async (value) => {
		const docRef = doc(db, 'reviewMessages', value.id);
		// update state to review message]
		await updateDoc(docRef, { repState: 'read' });
		if (value.esignDocumentId !== null) {
			setEsignDocumentId(value.esignDocumentId);
			setService(value.service);
			setOpenContractSignedView(true);
		} else {
			setOpenContractSignedView(false);
		}
	};

	// handle delete message
	const handleDelete = (document) => {
		setActionModalOpen(true);
		setDocActionId(document.id);
		setAction('Delete');
	};

	// handle archive message
	const handleArchive = (document) => {
		setActionModalOpen(true);
		setDocActionId(document.id);
		setAction('Archive');
	};

	const handleCloseActionModal = () => {
		setActionModalOpen(false);
		setDocActionId(null);
		setAction(null);
	};

	// handle close not found modal
	const handleCloseNotFoundModal = () => {
		setNotFoundModalOpen(false);
	};

	// handle open not found modal
	const handleNotFoundModal = () => {
		setNotFoundModalOpen(true);
	};

	// transform date timeStamp to en-US date.
	const getDateFormatted = (seconds) => {
		if (seconds) {
			const newDate = new Date(seconds * 1000);
			return newDate.toLocaleDateString('en-US');
		} else {
			return 'No Submitted Date';
		}
	};

	const getQueryData = (queryData, userType) => {
		onSnapshot(queryData, (snapshot) => {
			let results = [];
			snapshot.docs.forEach((doc) => {
				const docData = doc.data();

				let resultData = {};

				if (userType === 'ashley') {
					resultData = {
						messageDate: docData.messageDate || 'No Date',
						subject: docData.subject || 'No Subject',
						fromName: docData.fromName || 'No Traveler Name',
						to: docData.to || 'No To',
						message: docData.message || 'No Message',
						actions: {
							messageDate: docData.messageDate || 'No Date',
							subject: docData.subject || 'No Subject',
							fromName: docData.fromName || 'No Traveler Name',
							to: docData.to || 'No To',
							message: docData.message || 'No Message',
							platform: docData.platform || 'No Platform',
							module: docData.module || 'No Module',
							receiver_id: docData.receiver_id || 'No Receiver ID',
							seen: docData.seen,
							sender_id: docData.sender_id || 'No Sender ID',
							docs: docData.docs || [],
							travelerEmail: docData.travelerEmail || 'No Traveler Email',
							travelerId: docData.travelerId || 'No Traveler ID',
						},
					};
				} else {
					resultData = {
						repName: doc.data()?.fromName || 'No Rep Name',
						dealName: doc?.data()?.dealName || 'No Deal Name',
						submittedDate: getDateFormatted(
							doc.data()?.submittedDate?.seconds || null
						),
						// pass document id to actions column to use it with icon actions.
						actions: {
							id: doc.id,
							state: doc.data()?.state,
							proposalId: doc.data()?.proposalId,
							selectedRows: doc.data()?.selectedRowsAqh,
							repState: doc.data()?.repState,
							denyReason: doc.data()?.denyReason,
							esignDocumentId:
								doc.data()?.documentGroupEid ||
								doc.data()?.documentHash ||
								doc.data()?.submissionId,
							userType: userType,
							service: doc.data().documentHash
								? 'xodo'
								: doc.data().submissionId
								? 'docuSeal'
								: 'anvil',
							partialURL: doc.data()?.partialURL || null,
						},
						state: doc.data()?.state,
						repState: doc.data()?.repState,
						typeMessage: doc.data()?.typeMessage || 'Manager Review',
					};
				}

				results.push(resultData);
			});
			setData(results);
		});
	};

	useEffect(() => {
		const getUserRole = async () => {
			const userRef = doc(db, 'users', user.uid);
			const userDoc = await getDoc(userRef);
			const userRole = userDoc?.data()?.['role'];
			const colRef = collection(db, 'reviewMessages');

			if (user.uid === ashleyUser) {
				// Get all messages from this user order by submitted date.
				const queryData = query(
					colRef,
					where('platform', '==', 'cogs'),
					where('module', '==', 'traveler'),
					where('receiver_id', '==', user.uid),
					orderBy('messageDate', 'desc')
				);
				getQueryData(queryData, 'ashley');
			}
			// Set messages table data based on user role.
			else if (userRole && isAdminVerify(userRole)) {
				setUserRole('admin');
				// Get all messages from firebase for admin order by submitted date.
				const queryData = query(
					colRef,
					where('state', 'in', ['unread', 'read', 'archived', 'approved', 'denied']),
					orderBy('submittedDate', 'desc')
				);
				getQueryData(queryData, 'admin');
			} else {
				setUserRole('rep');
				const queryData = query(
					colRef,
					where('employeeEmail', '==', user.email),
					where('state', 'in', [
						'approved',
						'denied',
						'managerSigned',
						'sentClient',
						'signedClient',
					]),
					orderBy('submittedDate', 'desc')
				);
				getQueryData(queryData, 'rep');
			}
		};
		getUserRole();
	}, [user.email, user.uid]);

	return (
		<MainBox
			sx={{
				boxShadow: (theme) => theme.shadows[4],
				borderRadius: 2,
				paddingX: 1.2,
				marginX: -1,
			}}
		>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					title={'Messages'}
					data={data || []}
					columns={user.uid === ashleyUser ? columnsTraveler : columns}
					options={{
						...options,
						page: page,
						onChangePage: handlePageChange,
						rowsPerPage: 25,
						rowsPerPageOptions: [25, 50, 100],
						selectToolbarPlacement: 'replace',
						responsive: 'standard',
						selectableRows: 'none',
					}}
				/>
				<DealMessagesDetailsModal
					openDealModal={openModal}
					setOpenDealModal={setOpenModal}
					detailsObj={modalDetails}
					onClose={() => {
						setOpenModal(false);
					}}
				/>
				<ContractSignedView
					openContractSignedView={openContractSignedView}
					setOpenContractSignedView={setOpenContractSignedView}
					documentId={esignDocumentId}
					service={service}
					setEsignDocumentId={setEsignDocumentId}
					setService={setService}
					onClose={() => {
						setOpenContractSignedView(false);
					}}
				/>
				<ActionModal
					actionModalOpen={actionModalOpen}
					setActionModalOpen={setActionModalOpen}
					document={docActionId}
					setDocument={setDocActionId}
					action={action}
					setAction={setAction}
					onCloseAction={handleCloseActionModal}
				/>
				<ReplyMessage
					replyMessageModal={replyMessageModal}
					setReplyMessageModal={setReplyMessageModal}
					currentMessage={currentMessage}
					setCurrentMessage={setCurrentMessage}
				/>
				<ViewMessage
					viewMessageModal={viewMessageModal}
					setViewMessageModal={setViewMessageModal}
					currentMessage={currentMessage}
					setCurrentMessage={setCurrentMessage}
				/>
				<NotFoundModal
					notFoundModalOpen={notFoundModalOpen}
					onCloseAction={handleCloseNotFoundModal}
				/>
			</ThemeProvider>
		</MainBox>
	);
};

export default MessagesTab;
