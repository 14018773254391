import React, { useState } from 'react';
import {
	useTheme,
	ThemeProvider,
	Box,
	Button,
	Typography,
	Grid,
} from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import MUIDataTable from 'mui-datatables';
import { optionsTableRewards } from './utils';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import OrderNotes from './OrderNotes';
import ShippingDetails from './ShippingDetails';
import LoadCards from './LoadCards';
import moment from 'moment';

const ListRewardCards = ({ orderCardsRecords }) => {
	const theme = useTheme();
	const [order, setOrder] = useState();
	const [open, setOpen] = useState(false);
	const [openShipping, setOpenShipping] = useState(false);
	const [openLoadCards, setOpenLoadCards] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);

	const handleOpenShipping = (order) => {
		setSelectedOrder(order);
		setOpenShipping(true);
	};

	const handleCloseShipping = () => {
		setSelectedOrder(null);
		setOpenShipping(false);
	};

	const handleOpenLoadCards = (order) => {
		setSelectedOrder(order);
		setOpenLoadCards(true);
	};

	const handleCloseLoadCards = () => {
		setSelectedOrder(null);
		setOpenLoadCards(false);
	};

	const closeModal = () => {
		setOrder();
		setOpen(false);
	};

	const renderStatus = (status, rowData) => {
		if (!rowData) {
			return <Typography color="textPrimary">Unknown status</Typography>;
		}

		let statusColor = 'textPrimary';
		let statusText = '';
		let ActionButton = null;

		switch (status) {
			case undefined:
				statusColor = 'error';
				statusText = `Available ${moment(rowData.orderDate)
					.date(15)
					.format('MM/DD/YYYY')}`;
				break;
			case 'Requested':
				statusColor = 'textPrimary';
				statusText = 'Requested';
				break;
			case 'Ordered':
				statusColor = 'success';
				statusText = 'Ordered';
				break;
			case 'Shipped':
				statusColor = 'textPrimary';
				statusText = 'Shipped';
				ActionButton = (
					<Button
						sx={{ textTransform: 'capitalize' }}
						onClick={() => handleOpenShipping(rowData)}
						color="primary"
						size="small"
					>
						Details
					</Button>
				);
				break;
			case 'Delivered':
				statusColor = 'textPrimary';
				statusText = 'Delivered |';
				ActionButton = (
					<Button
						sx={{ textTransform: 'capitalize' }}
						onClick={() => handleOpenLoadCards(rowData)}
						color="success"
						size="small"
					>
						Load Cards
					</Button>
				);
				break;
			case 'Received':
				statusColor = 'textPrimary';
				statusText = 'Received';
				break;
			case 'Fulfilled':
				statusColor = 'textPrimary';
				statusText = 'Fulfilled';
				break;
			case 'Failed':
				statusColor = 'textPrimary';
				statusText = 'Failed';
				break;
			default:
				statusColor = 'textPrimary';
				statusText = 'Unknown status';
		}

		return (
			<Box display="flex" alignItems="center">
				<Typography color={statusColor}>{statusText}</Typography>
				{ActionButton}
			</Box>
		);
	};

	const columns = [
		{
			name: 'dealNumber',
			label: 'Deal #',
		},

		{
			name: 'cardDueDate',
			label: 'Card Due Date',
		},
		{
			name: 'balance',
			label: 'balance',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					return value ? `$ ${value}` : '$ 0';
				},
			},
		},
		{
			name: 'numberOfCards',
			label: 'Number Of Cards',
		},
		{
			name: 'denomination',
			label: 'Denomination',
		},
		{
			name: 'status',
			label: 'Order Status',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowData = orderCardsRecords[tableMeta.rowIndex];
					return renderStatus(value, rowData);
				},
			},
		},
		{
			name: 'notes',
			label: 'Notes',
			options: {
				customBodyRender: (_value, tableMeta) => {
					const value = orderCardsRecords[tableMeta.rowIndex];

					return (
						<div>
							<Grid item>
								<Box>
									<SpeakerNotesIcon
										sx={{ cursor: 'pointer' }}
										onClick={() => {
											setOrder(value);
											setOpen(true);
										}}
									></SpeakerNotesIcon>
								</Box>
							</Grid>
						</div>
					);
				},
			},
		},
	];

	return (
		<>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						data={orderCardsRecords || []}
						columns={columns}
						options={{
							...optionsTableRewards,
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					></MUIDataTable>
					<OrderNotes open={open} closeModal={closeModal} order={order} />
					<ShippingDetails
						order={selectedOrder}
						open={openShipping}
						onClose={handleCloseShipping}
					/>
					<LoadCards
						order={selectedOrder}
						open={openLoadCards}
						onClose={handleCloseLoadCards}
					/>
				</ThemeProvider>
			</MainBox>
		</>
	);
};
export default ListRewardCards;
