/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Tooltip, Box } from '@mui/material';
import AreYouSure from '../../../components/AreYouSure';
import { db } from '../../../firebase/config';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import {
	deleteDoc,
	doc,
	updateDoc,
	getDocs,
	collection,
	query,
	where,
	documentId,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { Verified as VerifiedIcon } from '@mui/icons-material';
import { baseURL } from '../../../utils';
import JSZip from 'jszip';
import { ThemeProvider, useTheme, styled } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { formatDateWithSlash } from '../../../utils';
import { getTheme, defaultOptionsTable } from '../utils';

const MainBox = styled(Box)(({ theme }) => ({
	margin: '20px auto',
	'.MuiPaper-root': {
		background: 'transparent',
		boxShadow: 'none',
		border: 0,
	},
}));

const Contracts = ({ contractsData, setContractData, user }) => {
	const [openDlt, setOpenDlt] = useState(false);
	const [esignDocuments, setEsignDocuments] = useState([]);
	const [contData, setcontData] = useState({ loading: false });
	const [esignContract, setEsignContract] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const esignsRef = collection(db, 'esigns');
	const contractsRef = collection(db, 'contracts');
	const theme = useTheme();
	const [page, setPage] = useState(0);

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	// bring the esigns documents form firebase that belong to the user
	useEffect(() => {
		const handleEsigns = async () => {
			try {
				const querySnapshot = query(esignsRef, where('uid', '==', user.uid));
				const data = await getDocs(querySnapshot);
				setEsignDocuments(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
			} catch (err) {
				console.log(err);
			}
		};
		handleEsigns();
	}, []);

	const handleDlt = async () => {
		try {
			setcontData((prev) => ({ ...prev, loading: true }));
			const { id } = contData;
			//get a contract by id
			const querySnapshot = query(contractsRef, where(documentId(), '==', id));
			const data = await getDocs(querySnapshot);
			const contract = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			//delete the contract from storage and firestore
			const urlRefContract = ref(getStorage(), contract[0].url);
			await deleteObject(urlRefContract);
			const firestoreRef = doc(db, 'contracts', id);
			await deleteDoc(firestoreRef);
			enqueueSnackbar('Deleted Successfully!', { variant: 'success' });
			setOpenDlt(false);
			setcontData({ loading: false });
		} catch (error) {
			setcontData((prev) => ({ ...prev, loading: false }));
			enqueueSnackbar(error.message, { variant: 'error' });
			console.log(error);
		}
	};

	useEffect(() => {
		const handleEsignedDocs = async (_) => {
			if (esignDocuments.length === 0) {
				return;
			} else {
				try {
					let results = await Promise.all(
						esignDocuments.map(async (v) => {
							if (v.data[3].documentGroupStatus === 'completed' && !v.service) {
								const documentGroupEid = v.data[4].documentGroupEid;
								const blob = await fetch(baseURL + '/fetchSignedDocs', {
									method: 'POST',
									body: JSON.stringify({
										documentGroupEid: documentGroupEid,
									}),
								});
								const zip = new JSZip();
								const response = await blob.json();
								if (response.statusCode === 200) {
									// unzip the file
									const unzipped = await zip.loadAsync(response.data.data);
									let blob = new Blob(
										[unzipped.files['blob.pdf']._data.compressedContent],
										{
											type: 'application/octet-stream',
										}
									);
									let pdfurl = window.URL.createObjectURL(blob);

									return {
										uid: v.uid,
										emailId: v.emailId,
										file: pdfurl,
									};
								}
							} else if (
								v.data[3].documentGroupStatus === 'completed' &&
								v.service &&
								v.service === 'xodoSign'
							) {
								return {
									uid: v.uid,
									emailId: v.emailId,
									service: v.service,
								};
							} else if (
								v.data[3].documentGroupStatus === 'completed' &&
								v.service &&
								v.service === 'docuSeal'
							) {
								return {
									uid: v.uid,
									emailId: v.emailId,
									service: v.service,
								};
							} else {
								return null;
							}
						})
					);
					setEsignContract(results);
				} catch (err) {
					console.log(err);
				}
			}
		};
		if (esignDocuments) {
			handleEsignedDocs();
		}
	}, [esignDocuments]);

	const handleOpenDelete = (contObj) => {
		setOpenDlt(true);
		setcontData(contObj);
	};

	useEffect(() => {
		// update the contract data with the signed url
		const updateContractData = async (contract, signedURL) => {
			if (!contract.ticked) {
				const docRef = doc(db, 'contracts', contract.id);
				await updateDoc(docRef, { ticked: true });
			}
			return { ...contract, url: signedURL, ticked: true };
		};

		const handleEsignTest = async () => {
			const updatedContracts = await Promise.all(
				contractsData.map(async (contract) => {
					// check if the emailId from the contract matches the emailId from the esignContract
					const esignContractMatch = esignContract.find(
						(rrr) => rrr?.emailId === contract?.emailId
					);
					// Handling of the signed contract depending on the service used
					if (esignContractMatch) {
						// if the emailId matches and the service is not xodoSign
						if (!esignContractMatch.service) {
							// update the contract data with the signed url
							return updateContractData(contract, esignContractMatch.file);
						} else if (
							esignContractMatch.service === 'xodoSign' ||
							esignContractMatch.service === 'docuSeal'
						) {
							// get the contract from the storage
							const contractSignedURL = contract?.signedContractURL;
							// get the blob from the contract
							const blob = await fetch(contractSignedURL).then((r) => r.blob());
							const pdfurl = window.URL.createObjectURL(blob);
							return updateContractData(contract, pdfurl);
						}
					}
					return contract;
				})
			);

			setContractData(updatedContracts);
		};

		if (esignContract?.length > 0) {
			handleEsignTest();
		}
	}, [esignContract]);

	// this function is for downloading the contract
	const handleDownload = async (url, name) => {
		try {
			const blob = await fetch(url)
				.then((r) => r.blob())
				.then((blobFile) => new File([blobFile], name, { type: blobFile.type }));
			const blobUrl = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = `${name}.pdf`;
			document.body.appendChild(link);
			link.click();
		} catch (err) {
			console.log(err);
		}
	};

	// options for table
	const options = {
		...defaultOptionsTable,
		// sort by date
		sortOrder: {
			name: 'modifiedDate',
			direction: 'desc',
		},
	};

	// columns for table
	const columns = [
		{
			name: 'contractName',
			label: 'Contract Name',
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<Typography
							width={'200px'}
							onClick={() => handleDownload(tableMeta.rowData[5], value)}
							sx={{ cursor: 'pointer' }}
						>
							{value}
						</Typography>
					);
				},
			},
		},
		{
			name: 'modifiedDate',
			label: 'Date',
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<Typography
							width={'100px'}
							onClick={() =>
								handleDownload(tableMeta.rowData[5], tableMeta.rowData[0])
							}
						>
							{formatDateWithSlash(value)}
						</Typography>
					);
				},
			},
		},
		{
			name: 'mainObj',
			label: 'Agreement Number',
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<Box textAlign={'center'} width={'70px'}>
							<Typography
								onClick={() =>
									handleDownload(tableMeta.rowData[5], tableMeta.rowData[0])
								}
								sx={{ cursor: 'pointer' }}
							>
								{value?.agreementNumber}
							</Typography>
						</Box>
					);
				},
				sortCompare: (order) => (rowA, rowB) => {
					const dateA = new Date(rowA?.rowData?.[2]?.agreementNumber);
					const dateB = new Date(rowB?.rowData?.[2]?.agreementNumber);

					return order === 'asc' ? dateA - dateB : dateB - dateA;
				},
			},
		},
		{
			name: 'ticked',
			label: 'Signed',
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<Box textAlign={'center'}>
							<IconButton
								onClick={() =>
									handleDownload(tableMeta.rowData[5], tableMeta.rowData[0])
								}
							>
								{value && <VerifiedIcon color="success" fontSize="medium" />}
							</IconButton>
						</Box>
					);
				},
			},
		},
		{
			name: 'state',
			label: 'State',
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<Typography
							onClick={() =>
								handleDownload(tableMeta.rowData[5], tableMeta.rowData[0])
							}
							sx={{ cursor: 'pointer', textAlign: 'center' }}
						>
							{value}
						</Typography>
					);
				},
			},
		},
		{
			name: 'url',
			label: 'URL',
			options: {
				sort: false,
				display: false,
			},
		},
		{
			name: 'actions',
			label: 'Actions',
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<Box width={'170px'} textAlign={'center'}>
							<Tooltip title="Download" placement="top" arrow>
								<IconButton
									onClick={() =>
										handleDownload(tableMeta.rowData[5], tableMeta.rowData[0])
									}
								>
									<DownloadIcon color="success" />
								</IconButton>
							</Tooltip>
							<Tooltip title="Delete" placement="top" arrow>
								<IconButton
									onClick={() =>
										handleOpenDelete({
											id: tableMeta.rowData[7], // id from column 7 in table
											contractName: tableMeta.rowData[0],
										})
									}
								>
									<DeleteIcon color="error" />
								</IconButton>
							</Tooltip>
						</Box>
					);
				},
			},
		},
		{
			name: 'id',
			label: 'Id',
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
	];

	return (
		<>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						title={'Contracts'}
						data={contractsData || []}
						columns={columns}
						options={{
							...options,
							page: page,
							onChangePage: handlePageChange,
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 50, 100],
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					/>
				</ThemeProvider>
			</MainBox>
			<AreYouSure
				open={openDlt && !!contData.id}
				setOpen={setOpenDlt}
				handleYes={() => handleDlt()}
				disabled={contData.loading}
				onClose={() => {
					setcontData({ loading: false });
					setOpenDlt(false);
				}}
			/>
		</>
	);
};

export default Contracts;
