import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Box,
	Button,
	CircularProgress,
	Alert,
} from '@mui/material';
import axios from 'axios';
import { baseURL } from '../../../../utils';

const LoadCards = ({ order, open, onClose }) => {
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState(null);

	useEffect(() => {
		if (result) {
			const timeoutId = setTimeout(() => {
				setResult(null);
			}, 3000);

			return () => clearTimeout(timeoutId);
		}
	}, [result]);

	const handleLoadCards = async () => {
		setLoading(true);
		setResult(null);
		try {
			const resp = await axios.post(baseURL + '/loadCardWithFounds', order);
			if (!resp.data) {
				throw new Error('No se recibió respuesta del servidor');
			}
			setResult({
				type: 'success',
				message: 'Cards loaded successfully',
			});
		} catch (error) {
			setResult({
				type: 'error',
				message: error.message || 'Error loading cards',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
				Load Cards
			</DialogTitle>
			<DialogContent>
				<Box p={2}>
					<Typography>
						{order?.qty} Cards for contract {order?.dealNumber} were received on{' '}
						{moment(order?.orderDate).format('MM/DD/YYYY')} at:
					</Typography>

					<Box my={3}>
						<Typography>{order?.address1}</Typography>
						<Typography>{order?.address2}</Typography>
						<Typography>
							{order?.city} {order?.state}, {order?.zip}
						</Typography>
					</Box>

					<Typography sx={{ my: 3 }}>
						And acknowledged on {moment(order?.orderDate).format('MM/DD/YYYY')} by:
					</Typography>

					<Box mt={3}>
						<Typography>{order?.name}</Typography>
						<Typography>{order?.mainStation}</Typography>
					</Box>
					{result && (
						<Box mt={2}>
							<Alert severity={result.type}>{result.message}</Alert>
						</Box>
					)}
				</Box>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
				<Button
					variant="contained"
					sx={{
						bgcolor: 'primary.main',
						color: 'white',
						px: 4,
						'&:hover': {
							bgcolor: 'primary.dark',
						},
					}}
					onClick={() => handleLoadCards()}
				>
					{loading ? (
						<>
							Loading... <CircularProgress size={20} sx={{ ml: 1 }} color="inherit" />
						</>
					) : (
						'Load Cards with Funds'
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LoadCards;
