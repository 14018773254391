/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box, IconButton, Grid, Typography, Tooltip } from '@mui/material';
import { styled, ThemeProvider, useTheme } from '@mui/material/styles';
import {
	collection,
	doc,
	getDoc,
	onSnapshot,
	query,
	where,
	orderBy,
} from 'firebase/firestore';
import { db } from '../../../firebase/config';
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate } from 'react-router-dom';
import useAuthContext from '../../../hooks/useAuthContext';
import { decompressDayParts } from '../../AQHCalculator/utils/utils';
import NotFoundModal from '../../Messages/components/NotFound';
import { getTheme } from '../utils';

const ProposalApprovals = () => {
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const [notFoundModalOpen, setNotFoundModalOpen] = useState(false);
	const theme = useTheme();
	const [page, setPage] = useState(0);

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const getBackgroundColor = (state) => {
		if (state === 'approved') {
			return 'rgb(177, 211, 179)';
		} else if (state === 'denied') {
			return 'rgb(232, 160, 159)';
		}
	};

	const columns = [
		{
			name: 'repName',
			label: 'Rep Name',
			options: {
				filter: true,
				sort: true,
				setCellProps: (value) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(value.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography key={tableMeta?.rowData?.[4]?.state}>{value}</Typography>
				),
			},
		},
		{
			name: 'dealName',
			label: 'Deal Name',
			options: {
				filter: true,
				sort: true,
				setCellProps: (value) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(value.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography key={tableMeta?.rowData?.[4]?.state}>{value}</Typography>
				),
			},
		},
		{
			name: 'state',
			label: 'State',
			options: {
				filter: true,
				sort: true,
				setCellProps: (value) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(value.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography key={tableMeta?.rowData?.[4]?.state}>{value}</Typography>
				),
			},
		},
		{
			name: 'submittedDate',
			label: 'Date',
			options: {
				filter: true,
				sort: true,
				setCellProps: (value) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(value.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography key={tableMeta?.rowData?.[4]?.state}>{value}</Typography>
				),
			},
		},
		{
			name: 'actions',
			label: 'Actions',
			options: {
				filter: false,
				sort: false,
				setCellProps: (props) => {
					return {
						style: {
							backgroundColor: getBackgroundColor(props.key),
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Box
						key={tableMeta?.rowData?.[4]?.state}
						display="flex"
						alignItems="center"
						justifyContent="center"
						py={0.8}
					>
						<Grid container spacing={1} columns={{ xs: 8, sm: 18, md: 18 }}>
							<Grid item xs={2} sm={4} md={4}>
								<Tooltip title="Go" placement="top" arrow>
									<IconButton
										onClick={(_) => {
											replyAction(value);
										}}
									>
										<ReplyIcon color="success" />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Box>
				),
			},
		},
	];

	const options = {
		filterType: 'dropdown',
		print: 'false',
		pagination: true,
	};

	const MainBox = styled(Box)(({ theme }) => ({
		margin: '20px auto',
		'.MuiPaper-root': {
			background: 'transparent',
			boxShadow: 'none',
			border: 0,
		},
	}));

	// handle reply action for manager review and rep review messages
	const replyAction = async (document) => {
		const proposalId = document.proposalId;
		// if proposal id is not found, show not found modal
		if (!proposalId) {
			handleNotFoundModal();
			return;
		}
		const docRef = doc(db, 'savedProposals', proposalId);
		try {
			const proposalDocument = await getDoc(docRef);
			if (
				proposalDocument.exists() &&
				proposalDocument.data()?.aqhSavedOptions[0]?.selectedRowsAqh
			) {
				// decompress dayparts
				const decompressedDayParts = await decompressDayParts(
					proposalDocument.data()?.aqhSavedOptions
				);
				const data = {
					...proposalDocument.data(),
					aqhSavedOptions: decompressedDayParts,
					id: document.proposalId,
					denyReason: document.denyReason || null,
				};
				// navigate to aqh calculator with proposal data
				navigate('/dashboard/aqhcalculator', { state: { data: data } });
			} else {
				// if proposal not found, show not found modal
				handleNotFoundModal();
			}
		} catch (error) {
			// if proposal not found, show not found modal
			handleNotFoundModal();
		}
	};

	// handle close not found modal
	const handleCloseNotFoundModal = () => {
		setNotFoundModalOpen(false);
	};

	// handle open not found modal
	const handleNotFoundModal = () => {
		setNotFoundModalOpen(true);
	};

	// transform date timeStamp to en-US date.
	const getDateFormatted = (seconds) => {
		if (seconds) {
			const newDate = new Date(seconds * 1000);
			return newDate.toLocaleDateString('en-US');
		} else {
			return 'No Submitted Date';
		}
	};

	const getQueryData = (queryData, userType) => {
		// retrive unread messages from firebase
		// watch realtime "reviewMessages" collection updates to update table
		onSnapshot(queryData, (snapshot) => {
			let results = [];
			snapshot.docs.forEach((doc) => {
				const resultData = {
					repName: doc.data()?.fromName || 'No Rep Name',
					dealName: doc?.data()?.dealName || 'No Deal Name',
					submittedDate: getDateFormatted(
						doc.data()?.submittedDate?.seconds || null
					),
					// pass document id to actions column to use it with icon actions.
					actions: {
						id: doc.id,
						state: doc.data()?.state,
						proposalId: doc.data()?.proposalId,
						selectedRows: doc.data()?.selectedRowsAqh,
						repState: doc.data()?.repState,
						denyReason: doc.data()?.denyReason,
						anvilDocumentId: doc.data()?.documentGroupEid || null,
						userType: userType,
					},
					state: doc.data()?.state,
					repState: doc.data()?.repState,
					typeMessage: doc.data()?.typeMessage || 'Manager Review',
				};
				results.push(resultData);
			});
			// set table data
			setData(results);
		});
	};

	useEffect(() => {
		const getUserRole = async () => {
			const colRef = collection(db, 'reviewMessages');

			// get all messages from firebase for admin order by submitted date.
			const queryData = query(
				colRef,
				where('state', 'in', ['unread', 'read', 'approved', 'denied']),
				orderBy('submittedDate', 'desc')
			);
			getQueryData(queryData, 'admin');
		};
		getUserRole();
	}, [user.email, user.uid]);

	return (
		<MainBox
			sx={{
				boxShadow: (theme) => theme.shadows[4],
				borderRadius: 2,
				paddingX: 1.2,
				marginX: -1,
			}}
		>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					title={'Proposal Approvals'}
					data={data || []}
					columns={columns}
					options={{
						...options,
						page: page,
						onChangePage: handlePageChange,
						rowsPerPage: 25,
						rowsPerPageOptions: [25, 50, 100],
						selectToolbarPlacement: 'replace',
						responsive: 'standard',
						selectableRows: 'none',
					}}
				/>
				<NotFoundModal
					notFoundModalOpen={notFoundModalOpen}
					onCloseAction={handleCloseNotFoundModal}
				/>
			</ThemeProvider>
		</MainBox>
	);
};

export default ProposalApprovals;
