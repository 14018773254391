import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Box,
} from '@mui/material';
import axios from 'axios';
import { baseURL } from '../../../../utils/index';

const ShippingDetails = ({ order, open, onClose }) => {
	const [orderTrackingInfo, setOrderTrackingInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const formattedOrderDate = order?.orderDate
		? moment(order.orderDate).date(15).format('MM/DD/YYYY')
		: '';

	useEffect(() => {
		// Clear previous data
		setOrderTrackingInfo(null);
		setError(null);

		const getTrackingInfo = async (orderId) => {
			try {
				const response = await axios.post(baseURL + '/getOrderTrackingInfo', {
					orderId,
				});
				if (!response?.data) {
					throw new Error('No se encontró información de tracking');
				}

				setOrderTrackingInfo(response.data);
				setError(null);
			} catch (err) {
				console.error('Error getting tracking info:', err);
				setError(err.message || 'Error al obtener información de tracking');
				setOrderTrackingInfo(null);
			} finally {
				setIsLoading(false);
			}
		};
		if (order?.orderId || order?.orderID) {
			const orderId = order?.orderID ?? order?.orderId;
			getTrackingInfo(orderId);
		}
	}, [order]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
				Shipping Details
			</DialogTitle>
			<DialogContent>
				<Box p={2}>
					{error && <Typography color="error">{error}</Typography>}
					{orderTrackingInfo && (
						// Show tracking number if available
						<Typography variant="body1">
							Tracking Number: {orderTrackingInfo?.trackingNumber || 'N/A'}
						</Typography>
					)}
					{!isLoading && !error && !orderTrackingInfo && (
						<Typography>Loading tracking info...</Typography>
					)}

					<Typography sx={{ pt: 1, pb: 2 }}>
						{order?.qty} ${order?.denomination} Cards Available as of{' '}
						{formattedOrderDate}
					</Typography>

					<Typography sx={{ pt: 4, pb: 4 }}>Ship to:</Typography>
					<Typography variant="body1">{order?.name}</Typography>
					<Typography variant="body1">{order?.address1}</Typography>
					<Typography variant="body1">{order?.address2}</Typography>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ShippingDetails;
