import React, { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AreYouSure from '../../../components/AreYouSure';
import { db } from '../../../firebase/config';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { deleteDoc, doc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';

const Container = styled(Box)(() => ({
	display: 'flex',
	// maxWidth: "800px",
	flexWrap: 'wrap',
	gap: '0.8rem',
}));

const TemplateBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'relative',
	width: '120px',
	gap: '5px',
	'&:hover': {
		'& .dltIcon': {
			opacity: 1,
			zIndex: 2,
		},
	},
	'& > .MuiTypography-root.name': {
		width: '70%',
		fontSize: '0.9rem',
		textAlign: 'center',
		textTransform: 'capitalize',
		fontWeight: '500',
	},
	'& > .MuiBox-root > .MuiTypography-root': {
		fontSize: '0.7rem',
		textAlign: 'center',
	},
	'& > svg': {
		width: '4em',
		height: '4em',
	},
}));

const Templates = ({ templatesData, uid }) => {
	const [openDlt, setOpenDlt] = useState(false);
	const [tempData, setTempData] = useState({ loading: false });
	const { enqueueSnackbar } = useSnackbar();

	const handleDlt = async () => {
		try {
			setTempData((prev) => ({ ...prev, loading: true }));
			const { url, id } = tempData;
			const storageRefa = ref(getStorage(), url);
			await deleteObject(storageRefa);
			const firestoreRef = doc(db, 'templates', id);
			await deleteDoc(firestoreRef);
			enqueueSnackbar('Deleted Successfully!', { variant: 'success' });
			setOpenDlt(false);
			setTempData({ loading: false });
		} catch (error) {
			setTempData((prev) => ({ ...prev, loading: false }));
			enqueueSnackbar(error.message, { variant: 'error' });
			console.log(error);
		}
	};

	const handleOpenDelete = (tempObj) => {
		setOpenDlt(true);
		setTempData(tempObj);
	};

	return (
		<Container>
			{templatesData.length > 0 ? (
				templatesData
					?.filter((v) => v.uid === uid)
					?.map((temp, idx) => (
						<TemplateBox key={temp.title + idx}>
							<IconButton
								aria-label="dlt-icon-button"
								className="dltIcon"
								onClick={() => handleOpenDelete(temp)}
								sx={{
									zIndex: 0,
									opacity: 0,
									position: 'absolute',
									left: -15,
									transition: 'all 0.3s ease',
								}}
							>
								<DeleteIcon
									sx={{ cursor: 'pointer', color: '#b00d24' }}
									fontSize="small"
								/>
							</IconButton>
							<Typography className="name">{temp.title}</Typography>
							<IconButton component="a" href={`${temp.url}`} target="_blank">
								<DescriptionIcon sx={{ fontSize: '5rem' }} />
							</IconButton>
							<Box>
								<Typography>modified</Typography>
								<Typography>{temp.modifiedDate?.toDate()?.toDateString()}</Typography>
							</Box>
						</TemplateBox>
					))
			) : (
				<div>No template found!</div>
			)}
			<AreYouSure
				open={openDlt && !!tempData.id}
				setOpen={setOpenDlt}
				handleYes={() => handleDlt()}
				disabled={tempData.loading}
				onClose={() => {
					setTempData({ loading: false });
					setOpenDlt(false);
				}}
			/>
		</Container>
	);
};

export default Templates;
