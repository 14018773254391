import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import ListContracts from './components/ListContracts';
import SavingsIcon from '@mui/icons-material/Savings';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { MainBox } from './utils/themes';
import useAuthContext from '../../hooks/useAuthContext';
import { useDocument } from 'react-firebase-hooks/firestore';
import { getRoles, getUserById } from './utils/utils';
import { Link } from 'react-router-dom';

const Cogs = () => {
	const { user } = useAuthContext();
	const [userName, setUserName] = useState();
	const [roles, setRoles] = useState();
	const [value] = useDocument(getUserById(user.uid));
	useEffect(() => {
		if (value) {
			const data = value.data();
			setUserName(`${data.firstName} ${data.lastName}`);
			const roles = getRoles(data);
			setRoles(roles);
		}
	}, [value]);

	return (
		<MainBox>
			{userName && <Typography variant="h4">Welcome {userName}</Typography>}

			<Container sx={{ marginY: 10 }}>
				<Grid container spacing={2}>
					<Grid xs={3} item>
						<Box sx={{ textAlign: 'center' }}>
							<Link to="/dashboard/vacationCredits" style={{ color: 'black' }}>
								<ConnectingAirportsIcon sx={{ fontSize: 150 }}></ConnectingAirportsIcon>
							</Link>
							<Typography variant="button" display="block">
								Vacation Credits
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={3}>
						<Box sx={{ textAlign: 'center' }}>
							{roles?.includes('giftCardManager') ? (
								<Link to="/dashboard/reward-cards" style={{ color: 'black' }}>
									<CreditCardIcon sx={{ fontSize: 150 }}></CreditCardIcon>
								</Link>
							) : (
								<CreditCardIcon sx={{ fontSize: 150 }}></CreditCardIcon>
							)}
							<Typography variant="button" display="block">
								Rewards Cards
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={3}>
						<Box sx={{ textAlign: 'center' }}>
							{roles?.includes('giftCardManager') ? (
								<Link to="/dashboard/cardsHome" style={{ color: 'black' }}>
									<CreditCardIcon sx={{ fontSize: 150 }}></CreditCardIcon>
								</Link>
							) : (
								<CreditCardIcon sx={{ fontSize: 150 }}></CreditCardIcon>
							)}
							<Typography variant="button" display="block">
								Amazon Cards
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={3}>
						<Box sx={{ textAlign: 'center' }}>
							<Link to="/dashboard/billPay" style={{ color: 'black' }}>
								<SavingsIcon sx={{ fontSize: 150 }}></SavingsIcon>
							</Link>
							<Typography variant="button" display="block">
								Bill Pay
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<ListContracts role={roles}></ListContracts>
		</MainBox>
	);
};

export default Cogs;
