import { useEffect, useState } from 'react';
import { getOrderCards } from '../utils/utils';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useRoles } from './useRoles';
import moment from 'moment';

const useRewardCards = () => {
	const [query, setQuery] = useState();
	const [orderCardsRecords, setOrderCards] = useState([]);
	const { roles, user } = useRoles();
	const [filter, setFilter] = useState('');

	useEffect(() => {
		if (roles) {
			if (roles?.includes('cogsManager')) {
				const query = getOrderCards();
				setQuery(query);
			}
		}
	}, [roles, user]);

	const [orderCards] = useCollection(query, {
		snapshotListenOptions: {
			includeMetadataChanges: true,
		},
	});

	useEffect(() => {
		if (orderCards?.docs) {
			const sortedDocs = orderCards.docs.sort((a, b) => {
				const dateA = a.data()?.timestamp?.seconds || 0;
				const dateB = b.data()?.timestamp?.seconds || 0;
				return dateB - dateA;
			});
			const docs = sortedDocs.map((item) => {
				const data = item.data();
				// If the data is not available, set the default values
				const orderTotal =
					data.orderTotal ?? Number(data.quantity) * Number(data.value);
				// By default it takes the value of dash solutions, otherwise the value we record
				const qty = data.qty ?? Number(data.quantity);

				return {
					...data,
					id: item.id,
					balance: orderTotal,
					cardDueDate: data.orderDate ?? moment(data.date).format('MM/DD/YYYY'),
					numberOfCards: qty ?? Number(data.quantity),
					denomination: Number(orderTotal / qty).toFixed(2),
				};
			});
			// Filter the order cards
			if (filter && filter.trim().length > 0) {
				const orderCardsFilter = docs.filter((item) => {
					const dealNumber = item.dealNumber;

					return dealNumber.toString().includes(filter.trim());
				});
				setOrderCards(orderCardsFilter);
			} else {
				// Set the order cards
				setOrderCards(docs);
			}
		}
	}, [orderCards, filter]);

	return {
		orderCardsRecords,
		setFilter,
	};
};
export default useRewardCards;
